<template>
  <van-field label="检测报告" v-if="!!order">
    <template #input>
      <van-button plain type="info" native-type="button" @click="viewReport" size="small" style="margin-right: 12px;" v-if="!isStaffView || item.reportURL !== ''">{{item.reportURL !== '' ? '查看报告' : '尚未出具'}}</van-button>
      <van-uploader :after-read="afterRead" v-if="isStaffView">
        <van-button plain type="info" native-type="button" size="small">{{item.reportURL === '' ? '上传报告' : '重传报告'}}</van-button>
      </van-uploader>
    </template>
  </van-field>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { Toast } from 'vant';
import { handleAPIError, isMobile } from './utils';

export default {
  name: 'detect-form-report-field',
  props: ['order', 'itemindex'],
  computed: {
    isStaffView() {
      return this.$route.path.split('/').includes('staff');
    },
    item() {
      return this.order.data.items[this.itemindex];
    },
  },
  methods: {
    afterRead(file) {
      const toast = Toast.loading({
        message: '上传中...',
        duration: 0,
        forbidClick: true,
      });
      const data = new FormData();
      data.append('file', _.get(file, 'file'));
      axios.post(`/api/staff/misc/upload-file`, data)
        .then((response) => {
          const { data } = response;
          return axios.post(`/api/staff/orders/${this.order.id}/items/${this.itemindex}/report`, data);
        })
        .then((response) => {
          const { data: { message } } = response;
          const toast2 = Toast.success({
            duration: 0,
            forbidClick: true,
            message: message,
          });
          setTimeout(() => {
            toast2.clear();
            this.$emit('success');
          }, 1500);
        })
        .catch((error) => {
          toast.clear();
          handleAPIError(error);
        });
    },
    viewReport(event) {
      event.stopPropagation();
      if (this.item.reportURL === '') {
        Toast('尚未出具检测报告');
        return;
      }
      if (this.order.paidAt === null && !this.isStaffView) {
        Toast('未付款订单不能查看报告');
        return;
      }
      if (this.isStaffView) {
        if (!isMobile()) {
          window.open(this.item.reportURL);
          return;
        }
        wx.previewFile({
          url: this.item.reportURL, // 需要预览文件的地址(必填，可以使用相对路径)
          name: this.item.reportName, // 需要预览文件的文件名，必须有带文件格式的后缀，例如.doc(不填的话取url的最后部分，最后部分是个包含格式后缀的文件名)
          size: this.item.reportSize // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
        });
        return
      }
      window.open(this.item.reportURL);
    },
  },
}
</script>

<style>

</style>
