import Vue from 'vue';
import VueRouter from 'vue-router';
import Vant from 'vant';
import '@vant/touch-emulator';

import 'vant/lib/index.css';

import App from './App.vue';

// staff
import StaffRecordList from './staff/RecordList.vue';
import StaffRecordForm from './staff/RecordForm.vue';
import StaffUserList from './staff/UserList.vue';
import StaffUserInfo from './staff/UserInfo.vue';
import StaffOrderList from './staff/OrderList.vue';
import StaffOrderInfo from './staff/OrderInfo.vue';
import StaffOrderInvoice from './staff/OrderInvoice.vue';

// customer
import SignupForm from './customer/SignupForm.vue';
import OrderList from './customer/OrderList.vue';
import OrderInfo from './customer/OrderInfo.vue';
import Checkout from './customer/Checkout.vue';
import OrderInvoice from './customer/OrderInvoice.vue';
import DetectForm from './customer/DetectForm.vue';
import MaintainForm from './customer/MaintainForm.vue';
import RepairForm from './customer/RepairForm.vue';
import MeterForm from './customer/MeterForm.vue';
import RecordList from './customer/RecordList.vue';
import RecordSurvey from './customer/RecordSurvey.vue';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Vant);

const routes = [
  // staff
  { path: '/staff/record/list', component: StaffRecordList },
  { path: '/staff/record/add', component: StaffRecordForm },
  { path: '/staff/user/list', component: StaffUserList },
  { path: '/staff/user/info', component: StaffUserInfo },
  { path: '/staff/order/list', component: StaffOrderList },
  { path: '/staff/order/info', component: StaffOrderInfo },
  { path: '/staff/order/invoice', component: StaffOrderInvoice },
  // customer
  { path: '/signup', component: SignupForm },
  { path: '/order/list', component: OrderList },
  { path: '/order/info', component: OrderInfo },
  { path: '/checkout', component: Checkout },
  { path: '/order/invoice', component: OrderInvoice },
  { path: '/service/detect', component: DetectForm },
  { path: '/service/maintain', component: MaintainForm },
  { path: '/service/repair', component: RepairForm },
  { path: '/service/meter', component: MeterForm },
  { path: '/record/list', component: RecordList },
  { path: '/record/survey', component: RecordSurvey },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
