<template>
  <div class="order-invoice" v-if="!!order">
    <van-nav-bar
      title="申请开票"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    <van-form :submit-on-enter="false" @submit="handleSubmit" :readonly="!!order.invoiceData">
      <van-cell-group title="发票抬头">
        <van-field
          v-model="taxerName"
          :required="!order.invoiceData"
          clearable
          label="名称"
          placeholder="请输入名称"
        />
        <van-field
          v-model="taxerNo"
          :required="!order.invoiceData"
          clearable
          label="税号"
          placeholder="请输入税号"
        />
        <van-field
          v-model="taxerAddr"
          clearable
          label="地址"
          placeholder="请输入地址"
        />
        <van-field
          v-model="taxerPhone"
          clearable
          label="电话"
          placeholder="请输入电话"
        />
        <van-field
          v-model="taxerBank"
          clearable
          label="开户行"
          placeholder="请输入开户行"
        />
        <van-field
          v-model="taxerAcct"
          clearable
          label="账号"
          placeholder="请输入账号"
        />
      </van-cell-group>
      <van-cell-group title="取票信息">
        <van-field
          :required="!order.invoiceData"
          label="取票方式"
        >
          <template #input>
            <van-radio-group v-model="deliver" direction="horizontal" :disabled="!!order.invoiceData">
              <van-radio :name="false">自取</van-radio>
              <van-radio :name="true">邮寄</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-if="deliver"
          v-model="deliverAddr"
          :required="!order.invoiceData"
          clearable
          label="邮寄地址"
          placeholder="请输入邮寄地址"
        />
        <van-field
          v-model="deliverName"
          :required="!order.invoiceData"
          clearable
          label="联系人"
          placeholder="请输入联系人"
        />
        <van-field
          v-model="deliverPhone"
          :required="!order.invoiceData"
          clearable
          label="联系电话"
          placeholder="请输入联系电话"
        />
      </van-cell-group>
      <div style="margin: 16px;" v-if="!order.invoiceData">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { Toast } from 'vant';
import { toastLoading, handleAPIError, fetchUserInfo } from './utils';

export default {
  name: 'order-invoice',
  data() {
    return {
      order: null,

      taxerName: '',
      taxerNo: '',
      taxerAddr: '',
      taxerPhone: '',
      taxerBank: '',
      taxerAcct: '',
      deliver: null,
      deliverAddr: '',
      deliverName: '',
      deliverPhone: '',
    };
  },
  created() {
    this.fetchInvoiceData();
    fetchUserInfo()
      .then((response) => {
        const { data: { user } } = response;
        this.deliverName = user.name;
        this.deliverPhone = user.mobile;
      });
  },
  methods: {
    handleSubmit() {
      const toast = toastLoading();
      const orderId = _.get(this.$route.query, 'orderId', '');
      const data = {
        taxerName: this.taxerName,
        taxerNo: this.taxerNo,
        taxerAddr: this.taxerAddr,
        taxerPhone: this.taxerPhone,
        taxerBank: this.taxerBank,
        taxerAcct: this.taxerAcct,
        deliver: this.deliver,
        deliverAddr: this.deliverAddr,
        deliverName: this.deliverName,
        deliverPhone: this.deliverPhone,
      };
      axios.post(`/api/orders/${orderId}/invoice`, data)
        .then(response => {
          const { data: { message } } = response;
          const toast2 = Toast.success({
            duration: 0,
            forbidClick: true,
            message: message,
          });
          setTimeout(() => {
            toast2.clear();
            this.fetchInvoiceData();
          }, 1500);
        })
        .catch(error => {
          toast.clear();
          handleAPIError(error);
        });
    },
    fetchInvoiceData() {
      const toast = toastLoading();
      const orderId = _.get(this.$route.query, 'orderId', '');
      axios.get(`/api/orders/${orderId}`)
        .then((response) => {
          const { data: { order } } = response;
          const { invoiceData } = order;
          this.order = order;
          if (invoiceData) {
            this.taxerName = invoiceData.taxerName;
            this.taxerNo = invoiceData.taxerNo;
            this.taxerAddr = invoiceData.taxerAddr;
            this.taxerPhone = invoiceData.taxerPhone;
            this.taxerBank = invoiceData.taxerBank;
            this.taxerAcct = invoiceData.taxerAcct;
            this.deliver = invoiceData.deliver;
            this.deliverAddr = invoiceData.deliverAddr;
            this.deliverName = invoiceData.deliverName;
            this.deliverPhone = invoiceData.deliverPhone;
          }
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          toast.clear();
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : wx.closeWindow();
    },
  },
}
</script>

<style>

</style>
