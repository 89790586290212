<template>
  <div class="record-survey" v-if="record !== null">
    <van-nav-bar
      title="运维服务评价"
      left-text="返回"
      left-arrow
      @click-left="goBack"
      fixed
    />
    <form :submit-on-enter="false" @submit="handleSubmit">
      <van-field
        readonly
        size="large"
        label="单位名称"
        :value="record.org.name"
      />
      <van-field
        readonly
        size="large"
        label="服务日期"
        :value="formatDate(record.maintainedAt)"
      />
      <van-field
        readonly
        size="large"
        label="服务人员"
        :value="record.maintainedBy.map(u => u.wxworkName).join('、')"
      />
      <van-field name="radio" label="服务是否满30分钟">
        <template #input>
          <van-radio-group v-model="enough" direction="horizontal">
            <van-radio name="yes">已满</van-radio>
            <van-radio name="no">不满</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="radio" label="服务是否满意">
        <template #input>
          <van-radio-group v-model="satisfy" direction="horizontal">
            <van-radio name="yes">满意</van-radio>
            <van-radio name="no">不满意</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="comment"
        rows="2"
        autosize
        label="意见或建议"
        type="textarea"
        maxlength="255"
        placeholder="请输入意见或建议"
        show-word-limit
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </form>
  </div>
</template>

<script>
import _ from 'lodash';
import queryString from 'query-string';
import dayjs from 'dayjs';
import axios from 'axios';
import { Toast } from 'vant';
import { handleAPIError, toastLoading } from './utils';

export default {
  data() {
    return {
      record: null,
      enough: '',
      satisfy: '',
      comment: '',
    };
  },
  created() {
    this.fetchRecordInfo();
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      const toast = toastLoading();
      const data = {
        enough: this.enough,
        satisfy: this.satisfy,
        comment: this.comment,
      };
      axios.post(`/api/records/${this.record.id}/survey`, data)
        .then((response) => {
          const { data: { message } } = response;
          const toast2 = Toast.success({
            duration: 0,
            forbidClick: true,
            message: message,
          });
          setTimeout(() => {
            toast2.clear();
            this.goBack();
          }, 1500);
        })
        .catch((error) => {
          toast.clear();
          handleAPIError(error);
        });
    },
    fetchRecordInfo() {
      const toast = toastLoading();
      const query = queryString.parse(window.location.search);
      const recordId = _.get(query, 'recordId');
      axios.get(`/api/records/${recordId}`)
        .then((response) => {
          const { data: { record } } = response;
          this.record = record;
          this.enough = record.enough;
          this.satisfy = record.satisfy;
          this.comment = record.comment;
          toast.clear();
        })
        .catch((error) => {
          toast.clear();
          handleAPIError(error);
        });
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    goBack() {
      wx.closeWindow();
    },
  }
}
</script>

<style>
.record-survey {
  padding-top: 54px;
}
</style>
