<template>
  <div class="staff-record-form">
    <van-nav-bar
      title="添加运维记录"
      left-text="返回"
      left-arrow
      @click-left="goBack"
      fixed
    />
    <van-form @submit="handleSubmit">
       <van-field
        required
        label="单位"
        placeholder="请选择单位"
        :value="org ? org.name: ''"
        is-link
        readonly
        @click="openOrgSelector"
      />
      <van-field
        required
        label="单位联系人"
        placeholder="请选择单位联系人"
        :value="observer ? `${observer.name}${observer.mobile}`: ''"
        is-link
        readonly
        @click="openObserverSelector"
      />
      <van-field
        required
        label="服务人员"
        placeholder="请选择服务人员"
        :value="wxworkUsers.map(u => u.name).join('、')"
        is-link
        readonly
        @click="openWxworkUserSelector"
      />
      <van-field
        required
        readonly
        clickable
        :value="formattedDate"
        label="服务日期"
        placeholder="请选择服务日期"
        @click="datePickerVisible = true"
      />
      <van-popup
        v-model="datePickerVisible"
        position="bottom"
        round
      >
        <van-datetime-picker
          v-model="date"
          type="date"
          title="请选择服务日期"
          @confirm="datePickerVisible = false"
          @cancel="datePickerVisible = false"
        />
      </van-popup>
      <van-field
        required
        label="服务记录表"
      >
        <template #input>
          <van-button native-type="button" @click="preview" size="small" style="margin-right: 12px;" v-if="attachURL !== null">预览文件</van-button>
          <van-uploader :after-read="afterRead">
            <van-button native-type="button" size="small">{{attachURL === null ? '上传文件' : '重传文件'}}</van-button>
          </van-uploader>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <staff-user-selector
      v-model="observerSelectorVisible"
       @change="handleObserverChange"
      @close="closeObserverSelector"
    />
    <staff-org-selector
      v-model="orgSelectorVisible"
      @change="handleOrgChange"
      @close="closeOrgSelector"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import axios from 'axios';
import { Toast } from 'vant';
import OrgSelector from './OrgSelector.vue';
import UserSelector from './UserSelector.vue';
import { toastLoading, handleAPIError, wxworkConfig, isMobile } from './utils';

export default {
  data() {
    return {
      org: null,
      observer: null,
      wxworkUsers: [],
      date: new Date(),
      attachURL: null,
      attachName: null,
      attachSize: null,

      observerSelectorVisible: false,
      orgSelectorVisible: false,
      datePickerVisible: false,
    };
  },
  created() {
    const toast = toastLoading();
    wxworkConfig();
    wx.ready(() => {
      toast.clear();
    });
  },
  computed: {
    formattedDate() {
      return this.date ? dayjs(this.date).format('YYYY年MM月DD日') : '';
    },
  },
  methods: {
    handleSubmit() {
      const toast = toastLoading();
      const data = {
        orgId: _.get(this.org, 'id'),
        observerId: _.get(this.observer, 'id'),
        maintainedBy: this.wxworkUsers.map(u => ({ wxworkId: u.id, wxworkName: u.name })),
        maintainedAt: this.date,
        attachURL: this.attachURL,
        attachName: this.attachName,
        attachSize: this.attachSize,
      };
      axios.post('/api/staff/records', data)
        .then(response => {
          const { data: { message } } = response;
          const toast2 = Toast.success({
            duration: 0,
            forbidClick: true,
            message: message,
          });
          setTimeout(() => {
            toast2.clear();
            this.goBack();
          }, 1500);
        })
        .catch(error => {
          toast.clear();
          handleAPIError(error);
        });
    },
    preview() {
      if (!isMobile()) {
        window.open(this.attachURL);
        return;
      }
      wx.previewFile({
        url: this.attachURL, // 需要预览文件的地址(必填，可以使用相对路径)
        name: this.attachName, // 需要预览文件的文件名，必须有带文件格式的后缀，例如.doc(不填的话取url的最后部分，最后部分是个包含格式后缀的文件名)
        size: this.attachSize // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
      });
    },
    afterRead(file) {
      const toast = Toast.loading({
        message: '上传中...',
        duration: 0,
        forbidClick: true,
      });
      const data = new FormData();
      data.append('file', _.get(file, 'file'));
      axios.post(`/api/staff/misc/upload-file`, data)
        .then((response) => {
          const { data: { url, name, size } } = response;
          this.attachURL = url;
          this.attachName = name;
          this.attachSize = size;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          toast.clear();
        });
    },
    handleObserverChange(observer) {
      this.observer = observer;
      this.closeObserverSelector();
    },
    closeObserverSelector() {
      this.observerSelectorVisible = false;
    },
    openObserverSelector() {
      this.observerSelectorVisible = true;
    },
    openWxworkUserSelector() {
      wx.invoke('selectEnterpriseContact', {
        fromDepartmentId: 0,
        mode: 'multi',
        type: ['user'],
        selectedUserIds: this.wxworkUsers.map(u => u.id),
      }, (res) => {
        const { err_msg, result } = res;
        if (err_msg === 'selectEnterpriseContact:ok') {
          this.wxworkUsers = result.userList;
        }
      });
    },
    handleOrgChange(org) {
      this.org = org;
      this.closeOrgSelector();
    },
    closeOrgSelector() {
      this.orgSelectorVisible = false;
    },
    openOrgSelector() {
      this.orgSelectorVisible = true;
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : wx.closeWindow();
    },
  },
  components: {
    [OrgSelector.name]: OrgSelector,
    [UserSelector.name]: UserSelector,
  },
};
</script>

<style>
.staff-record-form {
  padding-top: 46px;
}
.staff-record-form .van-field__error-message {
  color: #969799 !important;
}
</style>
