<template>
  <van-popup
    :value="value"
    :close-on-click-overlay="false"
    position="bottom"
  >
    <div style="height: 100vh; display: flex; flex-direction: column">
      <van-nav-bar
        title="选择单位联系人"
        left-text="关闭"
        @click-left="handleClose"
      />
      <van-search
        v-model="keyword"
        input-align="center"
        placeholder="搜索姓名、手机号或单位名称"
      />
      <div v-if="loading" style="flex: auto; display: flex; justify-content: center; align-items: center">
        <van-loading vertical>加载中...</van-loading>
      </div>
      <div v-if="!loading" style="flex: auto; overflow: scroll;">
        <van-cell
          v-for="user in filteredUsers"
          :key="user.id"
          :title="`${user.name} (${user.mobile})`"
          :label="user.org ? user.org.name : '未指定单位'"
          @click="handleChange(user)"
          clickable
        />
      </div>
    </div>
  </van-popup>
</template>

<script>
import axios from 'axios';
import { handleAPIError } from './utils';

export default {
  name: 'staff-user-selector',
  data() {
    return {
      keyword: '',
      loading: false,
      users: null,
    };
  },
  computed: {
    filteredUsers() {
      if (this.keyword === '') {
        return this.users;
      }
      return this.users.filter(u => u.name.includes(this.keyword) || u.mobile.includes(this.keyword) || (u.org && u.org.name.includes(this.keyword)))
    },
  },
  methods: {
    handleChange(org) {
      this.$emit('change', org);
    },
    handleClose() {
      this.$emit('close');
    },
    fetchUserList() {
      this.loading = true;
      axios.get(`/api/staff/users`)
        .then(response => {
          const { data: { users } } = response;
          this.users = users;
        })
        .catch(error => {
          handleAPIError(error);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    value(value) {
      if (value) this.fetchUserList();
    },
  },
  props: { value: Boolean },
}
</script>

<style>

</style>
