<template>
  <div class="record-list">
    <van-nav-bar title="运维记录" fixed />
    <van-pull-refresh v-model="refreshing" @refresh="handleRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="handleLoad"
      >
        <van-panel
          style="margin-top: 8px;"
          v-for="record in records"
          :key="record.id"
          :title="record.org.name"
          :status="formatDate(record.maintainedAt)"
        >
          <div style="padding: 0 16px; font-size: 14px;">
            <p>单位联系人：{{record.observer.name}}{{record.observer.mobile}}</p>
            <p>记录人员：{{record.creatorWxworkName}}</p>
            <p>服务人员：{{record.maintainedBy.map(u => u.wxworkName).join('、')}}</p>
          </div>
          <template #footer>
            <div style="display: flex; justify-content: flex-end;">
              <van-button size="small" @click="preview($event, record)">查看记录表</van-button>
            </div>
          </template>
        </van-panel>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { handleAPIError } from './utils';

const limit = 10;

export default {
  data() {
    return {
      records: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      offset: 0,
    };
  },
  methods: {
    preview(event, record) {
      event.preventDefault();
      window.open(record.attachURL);
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD');
    },
    handleRefresh() {
      axios.get('/api/records', { params: { offset: 0, limit: limit } })
        .then((response) => {
          const { data } = response;
          this.records = data.records;
          this.offset = limit;
          this.finished = data.records.length < limit;
          this.error = false;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          this.refreshing = false;
        });
    },
    handleLoad() {
      axios.get('/api/records', { params: { offset: this.offset, limit: limit } })
        .then((response) => {
          const { data } = response;
          this.records = [...this.records, ...data.records];
          this.offset += limit;
          this.finished = data.records.length < limit;
          this.error = false;
        })
        .catch((error) => {
          this.error = true;
          handleAPIError(error);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.record-list {
  padding-top: 46px;
}
.record-list .van-panel__footer .van-button {
  margin-left: 8px;
}
.record-list .van-panel__header-value {
  color: #969799 ;
}
</style>
