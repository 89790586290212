import _ from 'lodash';
import axios from 'axios';
import nzhcn from 'nzh/cn';
import queryString from 'query-string';
import { Toast, Dialog } from 'vant';

export const handleAPIError = (error) => {
  const { response } = error;
  if (response) {
    const { data, status } = response;
    switch (status) {
      case 401: {
        Dialog.alert({
          message: '请登录以继续当前操作',
          confirmButtonText: '立即登录',
          confirmButtonColor: '#1989fa',
        }).then(() => {
          window.location.href = getSigninURI();
        });
        break;
      }
      case 409: {
        const query = queryString.parse(window.location.search);
        window.location.href = _.get(query, 'continue', '/');
        break;
      }
      case 422: {
        toastFail(_.head(_.values(data.validationErrors)));
        break;
      }
      default: {
        toastFail(data.message ? data.message : (data ? data : '未知错误'));
        break;
      }
    }
  } else {
    toastFail(error.message);
  }
};

export const fetchUserInfo = () => {
  return axios.get('/api/users/~');
};

export const toastLoading = () => {
  return Toast.loading({
    message: '加载中...',
    duration: 0,
    forbidClick: true,
  });
};

export const toastFail = (message) => {
  return Toast({
    message: message,
    duration: 3000,
  });
};

export const getSigninURI = () => {
  const { pathname, search } = window.location;
  const continueURI = encodeURIComponent(pathname + search);
  return `/api/wxmp-signin?continue=${continueURI}`;
};

export const wxConfig = () => {
  axios.get('/api/wxconfig')
    .then((response) => {
      const { data: { wxxmpConfig } } = response;
      wx.config({
        ...wxxmpConfig,
        jsApiList: ['getLocation', 'openLocation'],
      });
    })
};

const currencyFormatter = new Intl.NumberFormat('zh-CN', { style: 'currency', currency: 'CNY' });

export const formatCurrency = (value) => {
  return currencyFormatter.format(value);
};

export const capitalizeCurrency = (value) => {
  return nzhcn.toMoney(value);
};

export const localizeOrderType = (value) => {
  switch (value) {
    case 'detect': return '试验检测';
    case 'repair': return '故障抢修';
    case 'maintain': return '运行维护';
    case 'meter': return '电能表安装及更换';
  }
  return '未定义';
};

export const localizeOrderState = (order) => {
  if (order.refundedAt !== null) {
    return '已退款';
  }
  if (order.quotedAt !== null) {
    if (order.paidAt !== null) {
      return '已支付';
    }
    return '待支付';
  }
  return '待报价';
};

export const canCheckout = (order) => {
  const { totalAmount, quotedAt, paidAt } = order;
  return (totalAmount !== '0' && quotedAt !== null && paidAt === null);
};

export const canInvoice = (order) => {
  const { paidAt, refundedAt } = order;
  return (paidAt !== null && refundedAt === null);
};

export const isMobile = () => {
  return navigator.userAgent.toLowerCase().includes("mobile")
};
