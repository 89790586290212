<template>
  <div :class="`detect-form${!order ? ' has-navbar': ''}`">
    <van-nav-bar
      v-if="!order"
      title="试验检测"
      :left-text="!!order ? '返回' : ''"
      :left-arrow="!!order"
      @click-left="goBack"
      fixed
    />
    <van-form :submit-on-enter="false" :readonly="!!order" @submit="handleSubmit">
      <van-cell-group :title="!!order ? '需求信息' : ''">
        <van-field
          v-model="customer"
          :required="!order"
          clearable
          label="单位名称"
          placeholder="请输入单位名称"
        />
        <van-field
          v-model="agent"
          :required="!order"
          clearable
          label="送检人"
          placeholder="请输入送检人"
        />
        <van-field
          v-model="phone"
          :required="!order"
          :clickable="$route.path.startsWith('/staff/')"
          :right-icon="$route.path.startsWith('/staff/') ? 'phone-o' : ''"
          @click="dial"
          clearable
          type="digit"
          label="联系电话"
          placeholder="请输入联系电话"
        />
        <van-field
          v-model="project"
          :required="!order"
          clearable
          label="送检设备使用项目名称"
          placeholder="请输入送检设备使用项目名称"
        />
        <van-field
          v-model="deviceAddr"
          clearable
          label="送检设备运行位置"
          placeholder="请输入送检设备运行位置"
        />
        <van-field
          :required="!order"
          label="送检设备状态"
        >
          <template #input>
            <van-radio-group v-model="deviceState" direction="horizontal" :disabled="!!order">
              <van-radio name="new">新</van-radio>
              <van-radio name="old">旧</van-radio>
              <van-radio name="defect">缺陷</van-radio>
              <van-radio name="other">其它</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          :required="!order"
          label="采样方式"
        >
          <template #input>
            <van-radio-group v-model="sampleType" direction="horizontal" :disabled="!!order">
              <van-radio name="send">送样</van-radio>
              <van-radio name="take">上门取样</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-if="sampleType === 'take'"
          v-model="sampleAddr"
          readonly
          :clickable="!order || $route.path.startsWith('/staff/')"
          :required="!order"
          :right-icon="$route.path.startsWith('/staff/') ? 'location-o' : ''"
          label="取样地址"
          placeholder="请选择取样地址"
          @click="openSampleLocPicker"
        />
        <van-popup v-if="!order" v-model="sampleLocPickerVisible" position="bottom">
          <div style="height: 100vh; width: 100vw; display: flex; flex-direction: column;">
            <van-nav-bar title="请选择取样地址" left-text="取消" @click-left="sampleLocPickerVisible = false" />
            <iframe
              style="flex: auto;"
              frameborder=0
              :src="`https://apis.map.qq.com/tools/locpicker?type=1&search=1&coord=${sampleCoord}&key=HEOBZ-TTSKG-A62Q4-IQMV5-AAPZZ-HTBFY&referer=wisdom`"
            />
          </div>
        </van-popup>
        <!-- <van-field
          v-if="sampleType === 'take' && sampleAmount !== null"
          readonly
          label="车辆服务费"
          :value="currency(sampleAmount)"
          :error-message="currencyCapital(sampleAmount)"
        /> -->
        <van-field
          :required="!order"
          readonly
          :clickable="!order"
          :value="shipDateScreen"
          label="送检日期"
          placeholder="请选择送检日期"
          @click="shipDatePickerVisible = true"
        />
        <van-popup
          v-if="!order"
          v-model="shipDatePickerVisible"
          position="bottom"
          round
        >
          <van-datetime-picker
            v-model="shipDate"
            :min-date="minDate"
            type="date"
            title="请选择送检日期"
            @confirm="shipDatePickerVisible = false"
            @cancel="shipDatePickerVisible = false"
          />
        </van-popup>
        <van-field
          :required="!order"
          readonly
          :clickable="!order"
          :value="sampleDateScreen"
          label="计划取样日期"
          placeholder="请选择计划取样日期"
          @click="sampleDatePickerVisible = true"
        />
        <van-popup
          v-if="!order"
          v-model="sampleDatePickerVisible"
          position="bottom"
          round
        >
          <van-datetime-picker
            v-model="sampleDate"
            :min-date="minDate"
            type="date"
            title="请选择计划取样日期"
            @confirm="sampleDatePickerVisible = false"
            @cancel="sampleDatePickerVisible = false"
          />
        </van-popup>
        <!-- <van-field
          readonly
          :clickable="!order"
          :value="manager"
          label="专属服务经理"
          placeholder="请选择专属服务经理"
          @click="managerPickerVisible = true"
        />
        <van-action-sheet
          v-if="!order"
          title="请选择专属服务经理"
          v-model="managerPickerVisible"
          :actions="managers"
          @select="handleManagerSelected"
        /> -->
      </van-cell-group>
      <van-cell-group v-for="(item, index) in items" :key="item.id">
        <template #title>
          <div style="display: flex; justify-content: space-between; align-items">
            <span>检测项目 ({{index + 1}})</span>
            <van-icon
              v-if="!order && items.length > 1"
              name="delete-o"
              @click="removeItem(index)"
            />
          </div>
        </template>
        <van-field
          v-model="item.text"
          :required="!order"
          readonly
          :clickable="!order"
          label="检测项目"
          placeholder="请选择检测项目"
          @click="item.pickerVisible = true"
        />
        <van-popup
          v-if="!order"
          v-model="item.pickerVisible"
          position="bottom"
          round
        >
          <van-cascader
            v-model="item.value"
            active-color="#1989fa"
            title="请选择检测项目"
            :options="options"
            @close="item.pickerVisible = false"
            @finish="handleCascaderFinish($event, item)"
          />
        </van-popup>
        <van-field
          v-if="item.value !== ''"
          readonly
          label="单价"
          :value="`${item.unitPrice}元/${item.unit}`"
        />
        <van-field
          v-model="item.qty"
          :required="!order"
          type="digit"
          label="数量"
          placeholder="请输入数量"
        />
        <van-field
          v-if="item.qty !== ''"
          readonly
          label="小计"
          :value="currency(parseInt(item.qty) * item.unitPrice)"
          :error-message="currencyCapital(parseInt(item.qty) * item.unitPrice)"
        />
        <detect-form-report-field :order="order" :itemindex="index" @success="emitReported" />
        <van-cell
          v-if="!order && index === items.length - 1"
          :clickable="!order"
          title="添加检测项目"
          icon="add-o"
          @click="addItem"
        />
      </van-cell-group>
      <van-cell-group title=" " v-if="estimatedAmount > 0">
        <van-field
          readonly
          label="预估费用"
          :value="currency(estimatedAmount)"
          :error-message="currencyCapital(estimatedAmount)"
        />
      </van-cell-group>
      <div style="margin: 16px;" v-if="!order">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import { Dialog } from 'vant';
import DetectFormReportField from './DetectFormReportField.vue';
import { capitalizeCurrency, formatCurrency, handleAPIError, toastLoading, wxConfig, fetchUserInfo } from './utils';

export default {
  name: 'detect-form',
  props: ['order', 'callback'],
  data() {
    return {
      customer: '',
      agent: '',
      phone: '',
      project: '',
      deviceAddr: '',
      deviceState: '',
      sampleType: '',
      sampleAddr: '',
      sampleCoord: '',
      // sampleAmount: null,
      shipDate: null,
      sampleDate: null,
      manager: '',
      items: [{ id: _.uniqueId('item_'), text: '', value: '', unit: '', unitPrice: 0, qty: '', pickerVisible: false }],

      options: [],
      managers: [{ name: "周亚君" }, { name: "张健" }, { name: "郑光海" }, { name: "张洪武" }, { name: "朱家雄" }, { name: "李佳桦" }],

      managerPickerVisible: false,
      minDate: new Date(),
      shipDatePickerVisible: false,
      sampleDatePickerVisible: false,
      sampleLocPickerVisible: false,
    };
  },
  created() {
    if (!this.order) {
      fetchUserInfo()
        .then((response) => {
          const { data: { user } } = response;
          this.customer = user.org ? user.org.name : '';
          this.agent = user.name;
          this.phone = user.mobile;
        });
      this.fetchOptions();
      wxConfig();
      wx.ready(() => this.getLocation());
      window.addEventListener('message', this.handleSampleLocPickerMessage);
    } else {
      const { data } = this.order;
      this.customer = data.customer;
      this.agent = data.agent;
      this.phone = data.phone;
      this.project = data.project;
      this.deviceAddr = data.deviceAddr;
      this.deviceState = data.deviceState;
      this.sampleType = data.sampleType;
      this.sampleAddr = data.sampleAddr;
      this.sampleCoord = data.sampleCoord;
      // this.sampleAmount = data.sampleAmount;
      this.shipDate = data.shipDate;
      this.sampleDate = data.sampleDate;
      this.manager = data.manager;
      this.items = data.items.map(item => ({...item, id: _.uniqueId('item_')}));
    }
  },
  beforeDestroy() {
    if (!this.order) {
      window.removeEventListener('message', this.handleSampleLocPickerMessage);
    }
  },
  computed: {
    shipDateScreen() {
      return this.shipDate ? dayjs(this.shipDate).format('YYYY年MM月DD日') : '';
    },
    sampleDateScreen() {
      return this.sampleDate ? dayjs(this.sampleDate).format('YYYY年MM月DD日') : '';
    },
    estimatedAmount() {
      let total = 0;
      this.items.forEach(item => {
        const { unitPrice, qty } = item;
        total = _.add(total, _.multiply(unitPrice, _.toSafeInteger(qty)));
      });
      // if (this.sampleType === 'take' && !!this.sampleAmount) {
      //   total = _.add(total, this.sampleAmount);
      // }
      return total;
    },
  },
  methods: {
    emitReported() {
      this.$emit('reported');
    },
    dial() {
      if (!this.$route.path.startsWith('/staff/')) {
        return;
      }
      if (navigator.userAgent.toLowerCase().includes('mobile')) {
        window.open(`tel:${this.phone}`);
        return;
      }
      Dialog.alert({
        message: '仅手机版企业微信支持拨打电话',
        confirmButtonColor: '#1989fa',
      });
    },
    openSampleLocPicker() {
      if (!this.order) {
        this.sampleLocPickerVisible = true;
        return;
      }
      const ua = navigator.userAgent.toLowerCase();
      if (ua.includes('wxwork')) {
        if (ua.includes('mobile')) {
          const [lat, lng] = this.sampleCoord.split(',');
          wx.openLocation({
            latitude: lat,
            longitude: lng,
            name: this.sampleAddr,
            address: this.sampleAddr,
          });
          return;
        }
        window.open(`https://apis.map.qq.com/uri/v1/marker?marker=coord:${this.sampleCoord};title:${this.sampleAddr};addr:${this.sampleAddr}&referer=wisdom`);
        return;
      }
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
    },
    currencyCapital(value) {
      return capitalizeCurrency(value);
    },
    currency(value) {
      return formatCurrency(value);
    },
    handleManagerSelected({ name }) {
      this.manager = name;
      this.managerPickerVisible = false;
    },
    handleSubmit() {
      const toast = toastLoading();
      const data = {
        customer: this.customer,
        agent: this.agent,
        phone: this.phone,
        project: this.project,
        deviceAddr: this.deviceAddr,
        deviceState: this.deviceState,
        sampleType: this.sampleType,
        sampleAddr: this.sampleAddr,
        sampleCoord: this.sampleCoord,
        shipDate: this.shipDate,
        sampleDate: this.sampleDate,
        manager: this.manager,
        items: this.items.map(item => ({...item, qty: _.toSafeInteger(item.qty)})),
      };
      axios.post('/api/detect-orders', data)
        .then(response => {
          const { data: { orderId } } = response;
          this.$router.push(`/order/info?orderId=${orderId}`);
        })
        .catch(error => {
          toast.clear();
          handleAPIError(error);
        });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    addItem() {
      this.items.push({ id: _.uniqueId('item_'), text: '', value: '', unit: '', unitPrice: 0, qty: '', pickerVisible: false });
    },
    handleCascaderFinish(event, item) {
      const { selectedOptions } = event;
      item.text = selectedOptions.map(opt => opt.text).join('/');
      item.unit = _.last(selectedOptions).unit;
      item.unitPrice = _.last(selectedOptions).unitPrice;
      item.pickerVisible = false;
    },
    handleSampleLocPickerMessage(event) {
      const { data: { module, poiname, latlng } } = event;
      if (module != 'locationPicker') {
        return;
      }
      // const toast = toastLoading();
      this.sampleAddr = poiname;
      this.sampleCoord = `${latlng.lat},${latlng.lng}`;
      this.sampleLocPickerVisible = false;
      // axios.get(`/api/lbs/distance?latlng=${latlng.lat},${latlng.lng}`)
      //   .then((response) => {
      //     const { data: { amount } } = response;
      //     this.sampleAmount = amount;
      //   })
      //   .catch((error) => {
      //     handleAPIError(error);
      //   })
      //   .then(() => {
      //     toast.clear();
      //   });
    },
    fetchOptions() {
      const toast = toastLoading();
      axios.get('/api/detect-options')
        .then((response) => {
          const { data: { detectOptions } } = response;
          this.options = detectOptions;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          toast.clear();
        });
    },
    getLocation() {
      const self = this;
      wx.getLocation({
        type: 'gcj02',
        success: function (res) {
          if (self.sampleCoord === '') {
            self.sampleCoord = `${res.latitude},${res.longitude}`;
          }
        }
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : wx.closeWindow();
    },
  },
  components: {
    [DetectFormReportField.name]: DetectFormReportField,
  },
};
</script>

<style>
.detect-form.has-navbar {
  padding-top: 46px;
}
.detect-form .van-field__error-message {
  color: #969799 !important;
}
</style>
