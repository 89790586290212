<template>
  <div class="staff-order-info has-foobar" v-if="!!order">
    <van-nav-bar
      title="订单详情"
      left-text="返回"
      left-arrow
      @click-left="goBack"
      fixed
      style="z-index: 1024;"
    />
    <van-tabs v-model="active" @click="tabsClick" color="#1989fa">
      <van-tab name="order" title="订单详情">
      </van-tab>
      <van-tab name="invoice" title="开票申请">
      </van-tab>
    </van-tabs>
    <van-cell-group title="订单信息">
      <van-field
        readonly
        label="订单编号"
        :value="order.id"
      />
      <van-field
        readonly
        label="订单类型"
        :value="localizeOrderType(order.type)"
      />
      <van-field
        readonly
        label="订单金额"
        :value="order.quotedAt !== null ? currency(order.totalAmount) : '待报价'"
        :error-message="order.quotedAt !== null ? currencyCapital(order.totalAmount) : ''"
      />
      <van-field
        readonly
        label="订单状态"
        :value="localizeOrderState(order)"
      />
      <van-field
        readonly
        label="下单时间"
        :value="formatDatetime(order.createdAt)"
      />
      <van-field
        v-if="order.type === 'detect' && order.estimatedSampleDate !== null"
        readonly
        label="预计取样日期"
        :value="formatDate(order.estimatedSampleDate)"
      />
      <van-field
        v-if="order.type === 'detect' && order.testerWxworkName !== ''"
        readonly
        label="试验员"
        :value="order.testerWxworkName"
      />
    </van-cell-group>
    <detect-form v-if="order.type === 'detect'" :order="order" @reported="fetchOrderInfo" />
    <repair-form v-else-if="order.type === 'repair'" :order="order" />
    <meter-form v-else-if="order.type === 'meter'" :order="order" />
    <maintain-form v-else-if="order.type === 'maintain'" :order="order" />
    <div class="foobar van-hairline--top" v-if="!order.quotedAt || !order.paidAt">
      <van-button type="info" size="small" @click="banktranserConfirm" v-if="order.quotedAt && !order.paidAt">汇款确认</van-button>
      <van-button type="info" size="small" @click="quoteDialogVisible = true" v-if="!order.quotedAt">报价</van-button>
    </div>
    <van-dialog
      v-model="quoteDialogVisible"
      title="报价"
      confirm-button-color="#1989fa"
      show-cancel-button
      @confirm="quote"
    >
      <van-field
        v-if="order.type === 'detect'"
        label="预计取样日期"
        :value="formatDate(estimatedSampleDate)"
        clickable
        readonly
        size="large"
        placeholder="请选择预计取样日期"
        @click="estimatedSampleDatePickerVisible = true"
      />
      <van-popup
        v-model="estimatedSampleDatePickerVisible"
        position="bottom"
        round
      >
        <van-datetime-picker
          v-model="estimatedSampleDate"
          type="date"
          title="请选择服务日期"
          @confirm="estimatedSampleDatePickerVisible = false"
          @cancel="estimatedSampleDatePickerVisible = false"
        />
      </van-popup>
      <van-field
        v-if="order.type === 'detect'"
        label="试验员"
        :value="wxworkTester ? wxworkTester.name : ''"
        clickable
        readonly
        size="large"
        placeholder="请选择试验员"
        @click="openWxworkUserSelector"
      />
      <van-field
        label="金额"
        v-model="totalAmount"
        clearable
        size="large"
        type="digit"
        placeholder="请输入金额"
        :error-message="currencyCapital(totalAmount)"
      />
    </van-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { Toast, Dialog } from 'vant';
import DetectForm from '../customer/DetectForm.vue';
import RepairForm from '../customer/RepairForm.vue';
import MeterForm from '../customer/MeterForm.vue';
import MaintainForm from '../customer/MaintainForm.vue';
import { handleAPIError, toastLoading, formatCurrency, capitalizeCurrency, localizeOrderType, localizeOrderState, wxworkConfig } from './utils';


export default {
  data() {
    return {
      active: 'order',
      order: null,

      // 报价窗口
      estimatedSampleDate: new Date(),
      wxworkTester: null,
      totalAmount: '',
      estimatedSampleDatePickerVisible: false,
      quoteDialogVisible: false,
    };
  },
  created() {
    wxworkConfig();
    this.fetchOrderInfo();
  },
  methods: {
    openWxworkUserSelector() {
      wx.invoke('selectEnterpriseContact', {
        fromDepartmentId: 0,
        mode: 'single',
        type: ['user'],
      }, (res) => {
        const { err_msg, result } = res;
        if (err_msg === 'selectEnterpriseContact:ok') {
          this.wxworkTester = result.userList[0];
        }
      });
    },
    tabsClick(name) {
      if (name === 'invoice') {
        this.$router.replace(`/staff/order/invoice?orderId=${this.order.id}`);
      }
    },
    localizeOrderType(value) {
      return localizeOrderType(value);
    },
    localizeOrderState(order) {
      return localizeOrderState(order);
    },
    currencyCapital(value) {
      return capitalizeCurrency(value);
    },
    currency(value) {
      return formatCurrency(value);
    },
    formatDatetime(date) {
      return dayjs(date).format('YYYY年MM月DD日 HH时mm分');
    },
    formatDate(date) {
      return dayjs(date).format('YYYY年MM月DD日');
    },
    banktranserConfirm() {
      Dialog.confirm({
        title: '汇款确认',
        message: '确认已收到此订单的汇款？',
        confirmButtonColor: '#1989fa',
      })
        .then(() => {
          const toast = toastLoading();
          axios.post(`/api/staff/orders/${this.order.id}/banktransfer-confirm`)
            .then((response) => {
              const { data: { message } } = response;
              const toast2 = Toast.success({
                duration: 0,
                forbidClick: true,
                message: message,
              });
              setTimeout(() => {
                toast2.clear();
                this.fetchOrderInfo();
              }, 1500);
            })
            .catch((error) => {
              toast.clear();
              handleAPIError(error);
            });
        });
    },
    quote() {
      const toast = toastLoading();
      const data = {
        totalAmount: this.totalAmount,
        wxworkTester: this.wxworkTester,
        estimatedSampleDate: this.estimatedSampleDate,
      };
      axios.post(`/api/staff/orders/${this.order.id}/quote`, data)
        .then((response) => {
          const { data: { message } } = response;
          const toast2 = Toast.success({
            duration: 0,
            forbidClick: true,
            message: message,
          });
          setTimeout(() => {
            toast2.clear();
            this.fetchOrderInfo();
          }, 1500);
        })
        .catch((error) => {
          toast.clear();
          handleAPIError(error);
        });
    },
    fetchOrderInfo() {
      const toast = toastLoading();
      const query = queryString.parse(window.location.search);
      const orderId = _.get(query, 'orderId');
      axios.get(`/api/staff/orders/${orderId}`)
        .then((response) => {
          const { data: { order } } = response;
          this.order = order;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          toast.clear();
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : wx.closeWindow();
    },
  },
  components: {
    [DetectForm.name]: DetectForm,
    [RepairForm.name]: RepairForm,
    [MeterForm.name]: MeterForm,
    [MaintainForm.name]: MaintainForm,
  },
}
</script>

<style>
.staff-order-info {
  padding: 46px 0 40px;
}
.staff-order-info.has-foobar {
  padding-bottom: 76px;
}
.staff-order-info .van-field__error-message {
  color: #969799 !important;
}
.staff-order-info .foobar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  padding: 8px 4px;
  box-sizing: border-box;
}
.staff-order-info .foobar > .van-uploader,
.staff-order-info .foobar > .van-button {
  flex: auto;
  width: 0;
  padding: 0;
  margin: 0 4px;
}
.staff-order-info .foobar > .van-uploader {
  display: flex;
}
.staff-order-info .foobar .van-uploader__wrapper {
  flex: auto;
  display: flex;
}
.staff-order-info .foobar .van-uploader__input-wrapper {
  flex: auto;
  display: flex;
}
.staff-order-info .foobar .van-uploader__input-wrapper .van-button {
  flex: auto;
}
.staff-order-info .foobar .van-button:first-child {
  margin-left: 0;
}
</style>
