<template>
  <div class="order-list">
    <van-nav-bar title="我的订单" fixed />
    <van-pull-refresh v-model="refreshing" @refresh="handleRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="handleLoad"
      >
        <van-panel
          style="margin-top: 8px;"
          v-for="order in orders"
          :key="order.id"
          :title="`订单编号：${order.id}`"
          :status="localizeOrderState(order)"
          @click="$router.push(`/order/info?orderId=${order.id}`)"
        >
          <div style="padding: 0 16px; font-size: 14px;">
            <p>订单类型：{{localizeOrderType(order.type)}}</p>
            <p>订单金额：{{order.quotedAt !== null ? currency(order.totalAmount) : '待报价'}}</p>
            <p>下单时间：{{formatDate(order.createdAt)}}</p>
          </div>
          <template #footer>
            <div style="display: flex; justify-content: flex-end;">
              <!-- <van-button size="small" v-if="order.reportURL !== ''" @click="viewReport($event, order)">下载报告</van-button> -->
              <van-button size="small" @click="invoice($event, order)">申请开票</van-button>
              <van-button size="small" type="info" @click="checkout($event, order)" :disabled="!!order.paidAt">{{!!order.paidAt ? '已支付' : '去支付'}}</van-button>
            </div>
          </template>
        </van-panel>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { Toast } from 'vant';
import { formatCurrency, handleAPIError, canCheckout, canInvoice, localizeOrderType, localizeOrderState } from './utils';

const limit = 10;

export default {
  data() {
    return {
      orders: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      offset: 0,
    };
  },
  methods: {
    // viewReport(event, order) {
    //   event.stopPropagation();
    //   if (order.paidAt === null) {
    //     Dialog.alert({
    //       message: '未付款订单不能下载报告',
    //       confirmButtonColor: '#1989fa',
    //     });
    //     return;
    //   }
    //   window.location.href = order.reportURL;
    // },
    invoice(event, order) {
      event.stopPropagation();
      if (canInvoice(order)) {
        this.$router.push(`/order/invoice?orderId=${order.id}`);
      } else {
        Toast('未付款或已退款订单不能申请开票');
      }
    },
    checkout(event, order) {
      event.stopPropagation();
      if (canCheckout(order)) {
        this.$router.push(`/checkout?orderId=${order.id}`);
      } else {
        Toast('未报价的订单不能进行支付');
      }
    },
    localizeOrderType(value) {
      return localizeOrderType(value);
    },
    localizeOrderState(order) {
      return localizeOrderState(order);
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
    currency(value) {
      return formatCurrency(value);
    },
    handleRefresh() {
      axios.get('/api/orders', { params: { offset: 0, limit: limit } })
        .then((response) => {
          const { data } = response;
          this.orders = data.orders;
          this.offset = limit;
          this.finished = data.orders.length < limit;
          this.error = false;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          this.refreshing = false;
        });
    },
    handleLoad() {
      axios.get('/api/orders', { params: { offset: this.offset, limit: limit } })
        .then((response) => {
          const { data } = response;
          this.orders = [...this.orders, ...data.orders];
          this.offset += limit;
          this.finished = data.orders.length < limit;
          this.error = false;
        })
        .catch((error) => {
          this.error = true;
          handleAPIError(error);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.order-list {
  padding-top: 46px;
}
.order-list .van-panel__footer .van-button {
  margin-left: 8px;
}
.order-list .van-panel__header-value {
  color: #969799 ;
}
</style>
