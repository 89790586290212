<template>
  <div class="staff-order-invoice" v-if="!!order">
    <van-nav-bar
      title="开票申请"
      left-text="返回"
      left-arrow
      fixed
      @click-left="goBack"
      style="z-index: 1024;"
    />
    <van-tabs v-model="active" @click="tabsClick" color="#1989fa">
      <van-tab name="order" title="订单详情">
      </van-tab>
      <van-tab name="invoice" title="开票申请">
      </van-tab>
    </van-tabs>
    <van-form disabled>
      <van-cell-group title="订单信息">
        <van-field
          label="订单编号"
          :value="order.id"
        />
        <van-field
          label="订单类型"
          :value="localizeOrderType(order.type)"
        />
        <van-field
          label="订单金额"
          :value="order.totalAmount !== '0' ? currency(order.totalAmount) : '待报价'"
          :error-message="order.totalAmount !== '0' ? currencyCapital(order.totalAmount) : ''"
        />
        <van-field
          label="订单状态"
          :value="localizeOrderState(order)"
        />
        <van-field
          label="下单时间"
          :value="formatDate(order.createdAt)"
        />
      </van-cell-group>
      <van-cell-group title="发票抬头">
        <van-field
          v-model="taxerName"
          label="名称"
        />
        <van-field
          v-model="taxerNo"
          label="税号"
        />
        <van-field
          v-model="taxerAddr"
          label="地址"
        />
        <van-field
          v-model="taxerPhone"
          label="电话"
        />
        <van-field
          v-model="taxerBank"
          label="开户行"
        />
        <van-field
          v-model="taxerAcct"
          label="账号"
        />
      </van-cell-group>
      <van-cell-group title="取票信息">
        <van-field
          label="取票方式"
        >
          <template #input>
            <van-radio-group v-model="deliver" direction="horizontal" disabled>
              <van-radio :name="false">自取</van-radio>
              <van-radio :name="true">邮寄</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-if="deliver"
          v-model="deliverAddr"
          label="邮寄地址"
        />
        <van-field
          v-model="deliverName"
          label="联系人"
        />
        <van-field
          v-model="deliverPhone"
          label="联系电话"
        />
      </van-cell-group>
    </van-form>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import { toastLoading, handleAPIError, localizeOrderType, localizeOrderState, capitalizeCurrency, formatCurrency } from './utils';

export default {
  name: 'staff-order-invoice',
  data() {
    return {
      active: 'invoice',

      order: null,

      taxerName: '',
      taxerNo: '',
      taxerAddr: '',
      taxerPhone: '',
      taxerBank: '',
      taxerAcct: '',
      deliver: null,
      deliverAddr: '',
      deliverName: '',
      deliverPhone: '',
    };
  },
  created() {
    this.fetchInvoiceData();
  },
  methods: {
    tabsClick(name) {
      if (name === 'order') {
        this.$router.replace(`/staff/order/info?orderId=${this.order.id}`);
      }
    },
    localizeOrderType(value) {
      return localizeOrderType(value);
    },
    localizeOrderState(order) {
      return localizeOrderState(order);
    },
    currencyCapital(value) {
      return capitalizeCurrency(value);
    },
    currency(value) {
      return formatCurrency(value);
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
    fetchInvoiceData() {
      const toast = toastLoading();
      const orderId = _.get(this.$route.query, 'orderId', '');
      axios.get(`/api/staff/orders/${orderId}`)
        .then((response) => {
          const { data: { order } } = response;
          const { invoiceData } = order;
          this.order = order;
          if (invoiceData) {
            this.taxerName = invoiceData.taxerName;
            this.taxerNo = invoiceData.taxerNo;
            this.taxerAddr = invoiceData.taxerAddr;
            this.taxerPhone = invoiceData.taxerPhone;
            this.taxerBank = invoiceData.taxerBank;
            this.taxerAcct = invoiceData.taxerAcct;
            this.deliver = invoiceData.deliver;
            this.deliverAddr = invoiceData.deliverAddr;
            this.deliverName = invoiceData.deliverName;
            this.deliverPhone = invoiceData.deliverPhone;
          }
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          toast.clear();
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : wx.closeWindow();
    },
  },
}
</script>

<style>
.staff-order-invoice {
  padding: 46px 0 40px;
}
.staff-order-invoice .van-field__error-message {
  color: #969799 !important;
}
.staff-order-invoice .van-field__control {
  color: #323233;
  -webkit-text-fill-color: currentcolor;
  cursor: text;
}
.staff-order-invoice .van-field__label {
  color: #646566;
}
</style>
