<template>
  <div :class="`repair-form${!order ? ' has-navbar': ''}`">
    <van-nav-bar
      v-if="!order"
      title="故障抢修"
      :left-text="!!order ? '返回' : ''"
      :left-arrow="!!order"
      @click-left="goBack"
      fixed
    />
    <van-form :submit-on-enter="false" :readonly="!!order" @submit="handleSubmit">
      <van-cell-group :title="!!order ? '需求信息' : ''">
        <van-field
          v-model="customer"
          :required="!order"
          clearable
          label="单位名称"
          placeholder="请输入单位名称"
        />
        <van-field
          v-model="agent"
          :required="!order"
          clearable
          label="联系人"
          placeholder="请输入联系人"
        />
        <van-field
          v-model="phone"
          :required="!order"
          :clickable="$route.path.startsWith('/staff/')"
          :right-icon="$route.path.startsWith('/staff/') ? 'phone-o' : ''"
          @click="dial"
          clearable
          type="digit"
          label="联系电话"
          placeholder="请输入联系电话"
        />
        <van-field
          v-model="addr"
          readonly
          :clickable="!order || $route.path.startsWith('/staff/')"
          :required="!order"
          :right-icon="$route.path.startsWith('/staff/') ? 'location-o' : ''"
          label="用电地址"
          placeholder="请选择用电地址"
          @click="openLocPicker"
        />
        <van-popup v-if="!order" v-model="locPickerVisible" position="bottom">
          <div style="height: 100vh; width: 100vw; display: flex; flex-direction: column;">
            <van-nav-bar title="请选择用电地址" left-text="取消" @click-left="locPickerVisible = false" />
            <iframe
              style="flex: auto;"
              frameborder=0
              :src="`https://apis.map.qq.com/tools/locpicker?type=1&search=1&coord=${coord}&key=HEOBZ-TTSKG-A62Q4-IQMV5-AAPZZ-HTBFY&referer=wisdom`"
            />
          </div>
        </van-popup>
        <van-field
          :required="!order"
          label="电压等级"
        >
          <template #input>
            <van-checkbox-group
              v-model="volts"
              direction="horizontal"
              :disabled="!!order"
            >
              <div class="volts-grid">
                <van-checkbox name="220V" shape="square">220V</van-checkbox>
                <van-checkbox name="400 (380) V" shape="square">400 (380) V</van-checkbox>
              </div>
              <div class="volts-grid">
                <van-checkbox name="10kV" shape="square">10kV</van-checkbox>
                <van-checkbox name="35kV" shape="square">35kV</van-checkbox>
              </div>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field
          v-model="descr"
          rows="2"
          autosize
          :required="!order"
          label="故障简述"
          type="textarea"
          maxlength="255"
          placeholder="请输入故障简述"
          :show-word-limit="!order"
        />
      </van-cell-group>
      <div v-if="!order" style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import axios from 'axios';
import { Dialog } from 'vant';
import { toastLoading, handleAPIError, wxConfig, fetchUserInfo } from './utils';

export default {
  name: 'repair-form',
  props: ['order'],
  data() {
    return {
      customer: '',
      addr: '',
      coord: '',
      agent: '',
      phone: '',
      volts: [],
      descr: '',

      locPickerVisible: false,
    };
  },
  created() {
    if (!this.order) {
      const toast = toastLoading();
      fetchUserInfo()
        .then((response) => {
          const { data: { user } } = response;
          this.customer = user.org ? user.org.name : '';
          this.agent = user.name;
          this.phone = user.mobile;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          toast.clear();
        });
      wxConfig();
      wx.ready(() => this.getLocation());
      window.addEventListener('message', this.handleLocPickerMessage);
    } else {
      const { data } = this.order;
      this.customer = data.customer;
      this.addr = data.addr;
      this.coord = data.coord;
      this.agent = data.agent;
      this.phone = data.phone;
      this.volts = data.volts;
      this.descr = data.descr;
    }
  },
  beforeDestroy() {
    if (!this.order) {
      window.removeEventListener('message', this.handleLocPickerMessage);
    }
  },
  methods: {
    dial() {
      if (!this.$route.path.startsWith('/staff/')) {
        return;
      }
      if (navigator.userAgent.toLowerCase().includes('mobile')) {
        window.open(`tel:${this.phone}`);
        return;
      }
      Dialog.alert({
        message: '仅手机版企业微信支持拨打电话',
        confirmButtonColor: '#1989fa',
      });
    },
    openLocPicker() {
      if (!this.order) {
        this.locPickerVisible = true;
        return;
      }
      const ua = navigator.userAgent.toLowerCase();
      if (ua.includes('wxwork')) {
        if (ua.includes('mobile')) {
          const [lat, lng] = this.coord.split(',');
          wx.openLocation({
            latitude: lat,
            longitude: lng,
            name: this.addr,
            address: this.addr,
          });
          return;
        }
        window.open(`https://apis.map.qq.com/uri/v1/marker?marker=coord:${this.coord};title:${this.addr};addr:${this.addr}&referer=wisdom`);
        return;
      }
    },
    handleSubmit() {
      const toast = toastLoading();
      const data = {
        customer: this.customer,
        addr: this.addr,
        coord: this.coord,
        agent: this.agent,
        phone: this.phone,
        volts: this.volts,
        descr: this.descr,
      };
      axios.post('/api/repair-orders', data)
        .then(response => {
          const { data: { orderId } } = response;
          this.$router.push(`/order/info?orderId=${orderId}&for=checkout`);
        })
        .catch(error => {
          toast.clear();
          handleAPIError(error);
        });
    },
    handleLocPickerMessage(event) {
      const { data: { module, poiname, latlng } } = event;
      if (module != 'locationPicker') {
        return;
      }
      this.addr = poiname;
      this.coord = `${latlng.lat},${latlng.lng}`;
      this.locPickerVisible = false;
    },
    getLocation() {
      const self = this;
      wx.getLocation({
        type: 'gcj02',
        success: function (res) {
          if (self.coord === '') {
            self.coord = `${res.latitude},${res.longitude}`;
          }
        }
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : wx.closeWindow();
    },
  },
};
</script>

<style>
.repair-form.has-navbar {
  padding-top: 46px;
}
.repair-form .van-field__error-message {
  color: #969799 !important;
}
.volts-grid {
  flex: auto;
  display: flex;
}
.volts-grid:first-child {
  margin-bottom: 8px;
}
</style>
