<template>
  <div class="staff-order-list">
    <div class="topbar">
      <van-search
        v-model="keyword"
        input-align="center"
        placeholder="搜索订单编号"
        v-on:clear="handleSearchClear"
        v-on:search="handleSearch"
      />
      <van-tabs v-model="orderState" color="#1989fa" @change="handleTabChange">
        <van-tab name="" title="全部"></van-tab>
        <van-tab name="waitquote" title="待报价" />
        <van-tab name="waitpay" title="待支付" />
        <van-tab name="paid" title="已支付" />
      </van-tabs>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="handleRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="handleLoad"
      >
        <van-cell
          v-for="order in orders"
          :key="order.id"
          clickable
          @click="$router.push(`/staff/order/info?orderId=${order.id}`)"
        >
          <template #title>
            <div style="display: flex; justify-content: space-between;">
              <span>订单编号：{{order.id}}</span>
              <span style="color: #1989fa;">{{localizeOrderState(order)}}</span>
            </div>
          </template>
          <template #label>
            <div>单位名称：{{order.data.customer}}</div>
            <div style="margin: 4px 0;">订单类型：{{localizeOrderType(order.type)}}</div>
            <div style="margin: 4px 0;">订单金额：{{order.quotedAt !== null ? currency(order.totalAmount) : '待报价'}}</div>
            <div>下单时间：{{formatDate(order.createdAt)}}</div>
          </template>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <staff-tabbar />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import Tabbar from './Tabbar.vue';
import { formatCurrency, handleAPIError, localizeOrderType, localizeOrderState } from './utils';

const limit = 10;

export default {
  data() {
    return {
      orders: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      offset: 0,

      keyword: _.get(this.$route.query, 'keyword', ''),
      orderState: _.get(this.$route.query, 'orderState', ''),
    };
  },
  methods: {
    localizeOrderType(value) {
      return localizeOrderType(value);
    },
    localizeOrderState(order) {
      return localizeOrderState(order);
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
    currency(value) {
      return formatCurrency(value);
    },
    handleSearch() {
      this.$router.replace({ path: '/staff/order/list', query: { ...this.$route.query, keyword: this.keyword } });
    },
    handleSearchClear() {
      const query = { ...this.$route.query };
      delete query.keyword;
      this.$router.replace({ path: '/staff/order/list', query });
    },
    handleTabChange() {
      this.$router.replace({ path: '/staff/order/list', query: { ...this.$route.query, orderState: this.orderState } });
    },
    handleRefresh() {
      axios.get('/api/staff/orders', { params: { offset: 0, limit: limit, keyword: this.keyword, orderState: this.orderState } })
        .then((response) => {
          const { data } = response;
          this.orders = data.orders;
          this.offset = limit;
          this.finished = data.orders.length < limit;
          this.error = false;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          this.refreshing = false;
        });
    },
    handleLoad() {
      axios.get('/api/staff/orders', { params: { offset: this.offset, limit: limit, keyword: this.keyword, orderState: this.orderState } })
        .then((response) => {
          const { data } = response;
          this.orders = [...this.orders, ...data.orders];
          this.offset += limit;
          this.finished = data.orders.length < limit;
          this.error = false;
        })
        .catch((error) => {
          this.error = true;
          handleAPIError(error);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/staff/order/list' && from.path === '/staff/order/list' && to.query !== from.query) {
        this.orders = [];
        this.loading = true;
        this.finished = false;
        this.refreshing = false;
        this.error = false;
        this.offset = 0;

        this.handleLoad();
      }
    },
  },
  components: {
    [Tabbar.name]: Tabbar,
  },
};
</script>

<style>
.staff-order-list {
  padding-top: 98px;
  padding-bottom: 50px;
}
.staff-order-list .topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1024;
}
.staff-order-list .van-panel__footer .van-button {
  margin-left: 8px;
}
.staff-order-list .van-panel__header-value {
  color: #969799 ;
}
.staff-order-list .van-cell__label {
  font-size: 14px;
}
</style>
