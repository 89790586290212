<template>
  <div class="order-info" v-if="!!order">
    <van-nav-bar
      title="订单详情"
      left-text="返回"
      left-arrow
      @click-left="goBack"
      fixed
    />
    <van-cell-group title="订单信息">
      <van-field
        readonly
        label="订单编号"
        :value="order.id"
      />
      <van-field
        readonly
        label="订单类型"
        :value="localizeOrderType(order.type)"
      />
      <van-field
        readonly
        label="订单金额"
        :value="order.quotedAt !== null ? currency(order.totalAmount) : '待报价'"
        :error-message="order.quotedAt !== null ? currencyCapital(order.totalAmount) : ''"
      />
      <van-field
        readonly
        label="下单时间"
        :value="formatDate(order.createdAt)"
      />
      <van-field
        readonly
        label="订单状态"
        :value="localizeOrderState(order)"
      />
    </van-cell-group>
    <detect-form v-if="order.type === 'detect'" :order="order" />
    <repair-form v-else-if="order.type === 'repair'" :order="order" />
    <meter-form v-else-if="order.type === 'meter'" :order="order" />
    <maintain-form v-else-if="order.type === 'maintain'" :order="order" />
    <div class="foobar van-hairline--top">
      <van-button @click="invoice($event, order)">申请开票</van-button>
      <van-button type="info" @click="checkout($event, order)" :disabled="!!order.paidAt">{{!!order.paidAt ? '已支付' : '去支付'}}</van-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { Toast } from 'vant';
import DetectForm from './DetectForm.vue';
import RepairForm from './RepairForm.vue';
import MeterForm from './MeterForm.vue';
import MaintainForm from './MaintainForm.vue';
import { handleAPIError, toastLoading, formatCurrency, capitalizeCurrency, localizeOrderType, canCheckout, canInvoice, localizeOrderState } from './utils';

export default {
  data() {
    return {
      order: null,
      iid: null,
    };
  },
  created() {
    this.fetchOrderInfo();
    this.iid = setInterval(() => this.fetchOrderInfo(), 2000);
  },
  beforeDestroy() {
    clearInterval(this.iid);
  },
  methods: {
    invoice(event, order) {
      event.stopPropagation();
      if (canInvoice(order)) {
        this.$router.push(`/order/invoice?orderId=${order.id}`);
      } else {
        Toast('未付款或已退款订单不能申请开票');
      }
    },
    checkout(event, order) {
      event.stopPropagation();
      if (canCheckout(order)) {
        this.$router.push(`/checkout?orderId=${order.id}`);
      } else {
        Toast('未报价的订单不能进行支付');
      }
    },
    localizeOrderType(value) {
      return localizeOrderType(value);
    },
    localizeOrderState(order) {
      return localizeOrderState(order);
    },
    currencyCapital(value) {
      return capitalizeCurrency(value);
    },
    currency(value) {
      return formatCurrency(value);
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
    fetchOrderInfo() {
      let toast;
      if (!this.order) {
        toast = toastLoading();
      }
      const query = queryString.parse(window.location.search);
      const orderId = _.get(query, 'orderId');
      axios.get(`/api/orders/${orderId}`)
        .then((response) => {
          const { data: { order } } = response;
          this.order = order;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          if (toast) {
            toast.clear();
          }
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : wx.closeWindow();
    },
  },
  components: {
    [DetectForm.name]: DetectForm,
    [RepairForm.name]: RepairForm,
    [MeterForm.name]: MeterForm,
    [MaintainForm.name]: MaintainForm,
  },
}
</script>

<style>
.order-info {
  padding: 46px 0 76px;
}
.order-info .van-field__error-message {
  color: #969799 !important;
}
.order-info .foobar {
  position: fixed;
  width: 100vw;
  bottom: 0;
  background-color: #fff;
  display: flex;
  padding: 8px;
  box-sizing: border-box;
}
.order-info .foobar .van-button {
  flex: auto;
  margin-left: 8px;
}
.order-info .foobar .van-button:first-child {
  margin-left: 0;
}
</style>
