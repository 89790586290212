<template>
  <div class="checkout" v-if="!!order">
    <van-nav-bar
      title="收银台"
      left-text="返回"
      left-arrow
      @click-left="goBack"
      fixed
    />
    <van-field
      readonly
      size="large"
      label="订单编号"
      :value="order.id"
    />
    <van-field
      readonly
      size="large"
      label="订单类型"
      :value="orderType"
    />
    <van-field
      readonly
      size="large"
      label="订单金额"
      :value="totalAmountCurrency"
      :error-message="totalAmountCapital"
    />
    <div style="margin: 8px">
      <van-button block type="primary" icon="wechat-pay" style="margin-bottom: 8px;" @click="invokeWxpay" :loading="invoking">微信支付</van-button>
      <van-button block icon="card" @click="acctVisible = true">银行汇款</van-button>
      <van-dialog
        v-model="acctVisible"
        confirm-button-text="知道了"
        confirm-button-color="#1989fa"
        title="收款账户"
      >
        <div style="margin: 16px;">
          <p>开户行：楚雄市农村商业银行万家坝支行</p>
          <p>开户名：云南实达计量检测有限公司</p>
          <p>账&emsp;号：2700004471578012</p>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import queryString from 'query-string';
import { Dialog } from 'vant';
import { handleAPIError, toastLoading, formatCurrency, capitalizeCurrency, localizeOrderType, canCheckout } from './utils';

export default {
  data() {
    return {
      order: null,
      acctVisible: false,
      invoking: false,
    };
  },
  created() {
    this.fetchOrderInfo();
  },
  computed: {
    orderType() {
      return localizeOrderType(this.order.type);
    },
    totalAmountCurrency() {
      return formatCurrency(this.order.totalAmount);
    },
    totalAmountCapital() {
      return capitalizeCurrency(this.order.totalAmount);
    },
  },
  methods: {
    invokeWxpay() {
      const self = this;
      self.invoking = true;
      axios.get(`/api/orders/${self.order.id}/prepay`)
        .then((response) => {
          const { data: { wxpayParams } } = response;
          function onBridgeReady() {
            WeixinJSBridge.invoke(
              'getBrandWCPayRequest', wxpayParams,
              function(res) {
                self.invoking = false;
                if(res.err_msg == "get_brand_wcpay_request:ok" ) {
                  //使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  self.goBack();
                }
            });
          }
          if (typeof WeixinJSBridge == "undefined") {
            if( document.addEventListener ) {
              document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
          } else {
            onBridgeReady();
          }
        })
        .catch((error) => {
          self.invoking = false;
          handleAPIError(error);
        });
    },
    fetchOrderInfo() {
      const toast = toastLoading();
      const query = queryString.parse(window.location.search);
      const orderId = _.get(query, 'orderId');
      axios.get(`/api/orders/${orderId}`)
        .then((response) => {
          const { data: { order } } = response;
          if (canCheckout(order)) {
            this.order = order;
          } else {
            let message = '未报价的订单不能进行支付';
            if (order.paidAt !== null) {
              message = '订单不能重复进行支付';
            }
            Dialog.alert({
              title: '错误',
              confirmButtonText: '知道了',
              confirmButtonColor: '#1989fa',
              message: message,
            })
              .then(() => {
                this.goBack();
              });
          }
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          toast.clear();
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : wx.closeWindow();
    },
  },
}
</script>

<style>
.checkout {
  padding-top: 54px;
}
.checkout .van-field__error-message {
  color: #969799 !important;
}
</style>
