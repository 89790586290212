<template>
  <div class="staff-user-list">
    <div class="topbar">
      <van-search
        v-model="keyword"
        input-align="center"
        placeholder="搜索姓名或手机号"
        v-on:clear="handleSearchClear"
        v-on:search="handleSearch"
      />
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="handleRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="handleLoad"
      >
        <van-cell
          v-for="user in users"
          :key="user.id"
          :title="user.name"
          :label="user.org ? user.org.name : '未指定单位'"
          :to="`/staff/user/info?userId=${user.id}`"
          is-link
        />
      </van-list>
    </van-pull-refresh>
    <staff-tabbar />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Tabbar from './Tabbar.vue';
import { handleAPIError } from './utils';

const limit = 10;

export default {
  data() {
    return {
      users: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      offset: 0,

      keyword: _.get(this.$route.query, 'keyword', ''),
    };
  },
  methods: {
    // dial(mobile) {
    //   if (navigator.userAgent.toLowerCase().includes('mobile')) {
    //     window.open(`tel:${mobile}`);
    //   } else {
    //     Dialog.alert({
    //       message: '仅手机版企业微信支持拨打电话',
    //       confirmButtonColor: '#1989fa',
    //     });
    //   }
    // },
    handleSearch() {
      this.$router.replace({ path: '/staff/user/list', query: { ...this.$route.query, keyword: this.keyword } });
    },
    handleSearchClear() {
      const query = { ...this.$route.query };
      delete query.keyword;
      this.$router.replace({ path: '/staff/user/list', query });
    },
    handleRefresh() {
      axios.get('/api/staff/users', { params: { offset: 0, limit: limit, keyword: this.keyword } })
        .then((response) => {
          const { data } = response;
          this.users = data.users;
          this.offset = limit;
          this.finished = data.users.length < limit;
          this.error = false;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          this.refreshing = false;
        });
    },
    handleLoad() {
      axios.get('/api/staff/users', { params: { offset: this.offset, limit: limit, keyword: this.keyword } })
        .then((response) => {
          const { data } = response;
          this.users = [...this.users, ...data.users];
          this.offset += limit;
          this.finished = data.users.length < limit;
          this.error = false;
        })
        .catch((error) => {
          this.error = true;
          handleAPIError(error);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/staff/user/list' && from.path === '/staff/user/list' && to.query !== from.query) {
        this.users = [];
        this.loading = true;
        this.finished = false;
        this.refreshing = false;
        this.error = false;
        this.offset = 0;

        this.handleLoad();
      }
    },
  },
  components: {
    [Tabbar.name]: Tabbar,
  },
};
</script>

<style>
.staff-user-list {
  padding-top: 54px;
  padding-bottom: 50px;
}
.staff-user-list .topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1024;
}
.staff-user-list .van-panel__footer .van-button {
  margin-left: 8px;
}
.staff-user-list .van-panel__header-value {
  color: #969799 ;
}
.staff-user-list .van-cell__label {
  font-size: 14px;
}
</style>
