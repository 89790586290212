<template>
  <van-tabbar route>
    <van-tabbar-item replace to="/staff/order/list" icon="orders-o">订单管理</van-tabbar-item>
    <van-tabbar-item replace to="/staff/user/list" icon="friends-o">客户管理</van-tabbar-item>
    <van-tabbar-item replace to="/staff/record/list" icon="records">运维记录</van-tabbar-item>
    <van-tabbar-item url="/admin/" icon="search">订单查询</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'staff-tabbar',
};
</script>

<style>

</style>
