<template>
  <div>
    <van-nav-bar title="新用户注册" />
    <van-form :submit-on-enter="false" @submit="submitForm">
      <van-field
        v-model="name"
        required
        clearable
        name="name"
        label="姓名"
        placeholder="请输入姓名"
      />
      <van-field
        v-model="mobile"
        required
        clearable
        type="digit"
        name="mobile"
        label="手机号"
        placeholder="请输入手机号"
      />
      <van-field
        v-model="vcode"
        center
        required
        clearable
        type="digit"
        name="vcode"
        label="验证码"
        placeholder="请输入验证码"
      >
        <template #button>
          <van-button
            size="small"
            native-type="button"
            :loading="vcodeSending"
            :disabled="vcodeCooldown > 0"
            @click="sendVcode"
          >
            {{ vcodeCooldown > 0 ? `${vcodeCooldown} 秒后可重新获取` : '获取验证码' }}
          </van-button>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import queryString from 'query-string';
import { handleAPIError, toastLoading } from './utils';

const vcodeInterval = 60; // seconds

export default {
  data() {
    return {
      name: "",
      mobile: "",
      vcode: "",
      vcodeSending: false,
      vcodeCooldown: 0,
    };
  },
  methods: {
    submitForm(values) {
      const toast = toastLoading();
      const data = {
        scene: 'signup',
        ...values,
      };
      axios.post('/api/signup', data)
        .then(() => {
          const query = queryString.parse(window.location.search);
          window.location.href = _.get(query, 'continue', '/');
        })
        .catch((error) => {
          toast.clear();
          handleAPIError(error);
        });
    },
    sendVcode() {
      this.vcodeSending = true;
      const data = {
        scene: 'signup',
        mobile: this.mobile,
      };
      axios.post('/api/send-vcode', data)
        .then(() => {
          this.vcodeCooldown = vcodeInterval;
          this.countdown();
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          this.vcodeSending = false;
        });
    },
    countdown() {
      setTimeout(() => {
        this.vcodeCooldown--;
        if (this.vcodeCooldown > 0) {
          this.countdown();
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
</style>
