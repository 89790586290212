<template>
  <van-popup
    :value="value"
    :close-on-click-overlay="false"
    position="bottom"
  >
    <div style="height: 100vh; display: flex; flex-direction: column">
      <van-nav-bar
        title="选择单位"
        left-text="关闭"
        right-text="添加"
        @click-left="handleClose"
        @click-right="dialogVisible = true"
      />
      <van-search
        v-model="keyword"
        input-align="center"
        placeholder="搜索单位名称"
      />
      <div v-if="loading" style="flex: auto; display: flex; justify-content: center; align-items: center">
        <van-loading vertical>加载中...</van-loading>
      </div>
      <div v-if="!loading" style="flex: auto; overflow: scroll;">
        <van-cell
          v-for="org in filteredOrgs"
          :key="org.id"
          :title="org.name"
          @click="handleChange(org)"
          clickable
        />
      </div>
      <van-dialog
        v-model="dialogVisible"
        title="添加单位"
        show-cancel-button
        confirm-button-color="#1989fa"
        :before-close="beforeDialogClose"
      >
        <van-field
          v-model="orgName"
          placeholder="请输入单位名称"
          autofocus
        />
      </van-dialog>
    </div>
  </van-popup>
</template>

<script>
import axios from 'axios';
import { Toast } from 'vant';
import { handleAPIError } from './utils';

export default {
  name: 'staff-org-selector',
  data() {
    return {
      keyword: '',
      loading: false,
      dialogVisible: false,
      orgs: null,
      orgName: '',
    };
  },
  computed: {
    filteredOrgs() {
      if (this.keyword === '') {
        return this.orgs;
      }
      return this.orgs.filter(o => o.name.includes(this.keyword))
    },
  },
  methods: {
    beforeDialogClose(action, done) {
      if (action === 'confirm') {
         axios.post(`/api/staff/orgs`, { name: this.orgName })
        .then(response => {
          this.orgName = '';
          done();
          const { data: { message } } = response;
          const toast2 = Toast.success({
            duration: 0,
            forbidClick: true,
            message: message,
          });
          setTimeout(() => {
            toast2.clear();
            this.fetchOrgList();
          }, 1500);
        })
        .catch(error => {
          handleAPIError(error);
          done(false);
        });
      } else {
        this.orgName = '';
        done();
      }
    },
    handleChange(org) {
      this.$emit('change', org);
    },
    handleClose() {
      this.$emit('close');
    },
    fetchOrgList() {
      this.loading = true;
      axios.get(`/api/staff/orgs`)
        .then(response => {
          const { data: { orgs } } = response;
          this.orgs = orgs;
        })
        .catch(error => {
          handleAPIError(error);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    value(value) {
      if (value) this.fetchOrgList();
    },
  },
  props: { value: Boolean },
}
</script>

<style>

</style>
