<template>
  <div class="staff-record-list">
    <div class="topbar">
      <van-search
        v-model="keyword"
        input-align="center"
        placeholder="搜索单位名称"
        v-on:clear="handleSearchClear"
        v-on:search="handleSearch"
      />
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="handleRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="handleLoad"
      >
        <van-panel
          style="margin-top: 8px;"
          v-for="record in records"
          :key="record.id"
          :title="record.org.name"
        >
          <div style="padding: 0 16px; font-size: 14px; color: #969799;">
            <p>单位联系人：{{record.observer.name}}{{record.observer.mobile}}</p>
            <p>记录人员：{{record.creatorWxworkName}}</p>
            <p>服务人员：{{record.maintainedBy.map(u => u.wxworkName).join('、')}}</p>
            <p>服务日期：{{formatDate(record.maintainedAt)}}</p>
            <p>创建日期：{{formatDate(record.createdAt)}}</p>
          </div>
          <template #footer>
            <div style="display: flex; justify-content: flex-end;">
              <van-button size="small" @click="preview($event, record)">查看记录表</van-button>
            </div>
          </template>
        </van-panel>
      </van-list>
    </van-pull-refresh>
    <div class="floating" @click="$router.push(`/staff/record/add`)">+</div>
    <staff-tabbar />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import Tabbar from './Tabbar.vue';
import { handleAPIError, isMobile, toastLoading, wxworkConfig } from './utils';

const limit = 10;

export default {
  data() {
    return {
      records: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      offset: 0,

      keyword: _.get(this.$route.query, 'keyword', ''),
    };
  },
  created() {
    const toast = toastLoading();
    wxworkConfig();
    wx.ready(() => {
      toast.clear();
    });
  },
  methods: {
    preview(event, record) {
      event.preventDefault();
      if (!isMobile()) {
        window.open(record.attachURL);
        return;
      }
      wx.previewFile({
        url: record.attachURL, // 需要预览文件的地址(必填，可以使用相对路径)
        name: record.attachName, // 需要预览文件的文件名，必须有带文件格式的后缀，例如.doc(不填的话取url的最后部分，最后部分是个包含格式后缀的文件名)
        size: record.attachSize // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
      });
    },
    formatDate(date) {
      return dayjs(date).format('YYYY年MM月DD日');
    },
    handleSearch() {
      this.$router.replace({ path: '/staff/record/list', query: { ...this.$route.query, keyword: this.keyword } });
    },
    handleSearchClear() {
      const query = { ...this.$route.query };
      delete query.keyword;
      this.$router.replace({ path: '/staff/record/list', query });
    },
    handleTabChange() {
      this.$router.replace({ path: '/staff/record/list', query: { ...this.$route.query } });
    },
    handleRefresh() {
      axios.get('/api/staff/records', { params: { offset: 0, limit: limit, keyword: this.keyword } })
        .then((response) => {
          const { data } = response;
          this.records = data.records;
          this.offset = limit;
          this.finished = data.records.length < limit;
          this.error = false;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          this.refreshing = false;
        });
    },
    handleLoad() {
      axios.get('/api/staff/records', { params: { offset: this.offset, limit: limit, keyword: this.keyword } })
        .then((response) => {
          const { data } = response;
          this.records = [...this.records, ...data.records];
          this.offset += limit;
          this.finished = data.records.length < limit;
          this.error = false;
        })
        .catch((error) => {
          this.error = true;
          handleAPIError(error);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/staff/record/list' && from.path === '/staff/record/list' && to.query !== from.query) {
        this.records = [];
        this.loading = true;
        this.finished = false;
        this.refreshing = false;
        this.error = false;
        this.offset = 0;

        this.handleLoad();
      }
    },
  },
  components: {
    [Tabbar.name]: Tabbar,
  },
};
</script>

<style>
.staff-record-list {
  padding-top: 54px;
  padding-bottom: 50px;
}
.staff-record-list .topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1024;
}
.staff-record-list .floating {
  position: fixed;
  right: 32px;
  bottom: 96px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1989fa;
  color: #fff;
  font-size: 32px;
  user-select: none;
}
.staff-record-list .floating:active {
  background-color: #177CE1;
}
.staff-record-list .van-panel__footer .van-button {
  margin-left: 8px;
}
.staff-record-list .van-panel__header-value {
  color: #969799;
}
</style>
