<template>
  <div :class="`maintain-form${!order ? ' has-navbar': ''}`">
    <van-nav-bar
      v-if="!order"
      title="运行维护"
      :left-text="!!order ? '返回' : ''"
      :left-arrow="!!order"
      @click-left="goBack"
      fixed
    />
    <van-form :submit-on-enter="false" :readonly="!!order" @submit="handleSubmit">
      <van-cell-group :title="!!order ? '需求信息' : ''">
        <van-field
          v-model="customer"
          :required="!order"
          clearable
          label="单位名称"
          placeholder="请输入单位名称"
        />
        <van-field
          v-model="agent"
          :required="!order"
          clearable
          label="联系人"
          placeholder="请输入联系人"
        />
        <van-field
          v-model="phone"
          :required="!order"
          :clickable="$route.path.startsWith('/staff/')"
          :right-icon="$route.path.startsWith('/staff/') ? 'phone-o' : ''"
          @click="dial"
          clearable
          type="digit"
          label="联系电话"
          placeholder="请输入联系电话"
        />
        <van-field
          v-model="addr"
          readonly
          :clickable="!order || $route.path.startsWith('/staff/')"
          :required="!order"
          :right-icon="$route.path.startsWith('/staff/') ? 'location-o' : ''"
          label="用电地址"
          placeholder="请选择用电地址"
          @click="openLocPicker"
        />
        <van-popup v-if="!order" v-model="locPickerVisible" position="bottom">
          <div style="height: 100vh; width: 100vw; display: flex; flex-direction: column;">
            <van-nav-bar title="请选择用电地址" left-text="取消" @click-left="locPickerVisible = false" />
            <iframe
              style="flex: auto;"
              frameborder=0
              :src="`https://apis.map.qq.com/tools/locpicker?type=1&search=1&coord=${coord}&key=HEOBZ-TTSKG-A62Q4-IQMV5-AAPZZ-HTBFY&referer=wisdom`"
            />
          </div>
        </van-popup>
        <van-field
          :required="!order"
          label="电压等级"
        >
          <template #input>
            <van-checkbox-group
              v-model="volts"
              direction="horizontal"
              :disabled="!!order"
            >
              <div class="volts-grid">
                <van-checkbox name="220V" shape="square">220V</van-checkbox>
                <van-checkbox name="400 (380) V" shape="square">400 (380) V</van-checkbox>
              </div>
              <div class="volts-grid">
                <van-checkbox name="10kV" shape="square">10kV</van-checkbox>
                <van-checkbox name="35kV" shape="square">35kV</van-checkbox>
              </div>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field
          :required="!order"
          label="服务期限"
        >
          <template #input>
            <van-radio-group v-model="period" direction="horizontal" :disabled="!!order">
              <van-radio name="1yr">1年</van-radio>
              <van-radio name="2yr">2年</van-radio>
              <van-radio name="3yr">3年</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          :required="!order"
          label="服务频次"
        >
          <template #input>
            <van-radio-group v-model="freq" direction="horizontal" :disabled="!!order">
              <div class="volts-grid">
                <van-radio name="monthly">每个月一次</van-radio>
                <van-radio name="quarterly">每季度一次</van-radio>
              </div>
              <div class="volts-grid">
                <van-radio name="semiannually">每半年一次</van-radio>
              </div>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          :required="!order"
          label="是否需要预防性试验检测"
        >
          <template #input>
            <van-radio-group v-model="detect" direction="horizontal" :disabled="!!order">
              <van-radio name="yes">是</van-radio>
              <van-radio name="no">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </van-cell-group>
      <van-cell-group v-for="(item, index) in items" :key="item.id">
        <template #title>
          <div style="display: flex; justify-content: space-between; align-items">
            <span>运维项目 ({{index + 1}})</span>
            <van-icon
              v-if="!order && items.length > 1"
              name="delete-o"
              @click="removeItem(index)"
            />
          </div>
        </template>
        <van-field
          v-model="item.text"
          :required="!order"
          readonly
          :clickable="!order"
          label="运维项目"
          placeholder="请选择运维项目"
          @click="item.pickerVisible = true"
        />
        <van-popup
          v-if="!order"
          v-model="item.pickerVisible"
          position="bottom"
          round
        >
          <van-cascader
            v-model="item.value"
            active-color="#1989fa"
            title="请选择运维项目"
            :options="options"
            @close="item.pickerVisible = false"
            @finish="handleCascaderFinish($event, item)"
          />
        </van-popup>
        <van-field
          v-model="item.qty"
          :required="!order"
          type="digit"
          :label="`数量 ${item.value !== '' ? ` (${item.unit})` : ''}`"
          placeholder="请输入数量"
        />
        <van-cell
          v-if="!order && index === items.length - 1"
          :clickable="!order"
          title="添加运维项目"
          icon="add-o"
          @click="addItem"
        />
      </van-cell-group>
      <div style="margin: 16px;" v-if="!order">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { Dialog } from 'vant';
import { handleAPIError, toastLoading, wxConfig, fetchUserInfo } from './utils';

export default {
  name: 'maintain-form',
  props: ['order'],
  data() {
    return {
      customer: '',
      addr: '',
      coord: '',
      agent: '',
      phone: '',
      volts: [],
      period: '',
      freq: '',
      detect: '',
      items: [{ id: _.uniqueId('item_'), text: '', value: '', unit: '', qty: '', pickerVisible: false }],

      options: [],

      locPickerVisible: false,
    };
  },
  created() {
    if (!this.order) {
      fetchUserInfo()
        .then((response) => {
          const { data: { user } } = response;
          this.customer = user.org ? user.org.name : '';
          this.agent = user.name;
          this.phone = user.mobile;
        });
      this.fetchOptions();
      wxConfig();
      wx.ready(() => this.getLocation());
      window.addEventListener('message', this.handleLocPickerMessage);
    } else {
      const { data } = this.order;
      this.customer = data.customer;
      this.addr = data.addr;
      this.coord = data.coord;
      this.agent = data.agent;
      this.phone = data.phone;
      this.volts = data.volts;
      this.period = data.period;
      this.freq = data.freq;
      this.detect = data.detect;
      this.items = data.items.map(item => ({...item, id: _.uniqueId('item_')}));
    }
  },
  beforeDestroy() {
    if (!this.order) {
      window.removeEventListener('message', this.handleLocPickerMessage);
    }
  },
  methods: {
    dial() {
      if (!this.$route.path.startsWith('/staff/')) {
        return;
      }
      if (navigator.userAgent.toLowerCase().includes('mobile')) {
        window.open(`tel:${this.phone}`);
        return;
      }
      Dialog.alert({
        message: '仅手机版企业微信支持拨打电话',
        confirmButtonColor: '#1989fa',
      });
    },
    openLocPicker() {
      if (!this.order) {
        this.locPickerVisible = true;
        return;
      }
      const ua = navigator.userAgent.toLowerCase();
      if (ua.includes('wxwork')) {
        if (ua.includes('mobile')) {
          const [lat, lng] = this.coord.split(',');
          wx.openLocation({
            latitude: lat,
            longitude: lng,
            name: this.addr,
            address: this.addr,
          });
          return;
        }
        window.open(`https://apis.map.qq.com/uri/v1/marker?marker=coord:${this.coord};title:${this.addr};addr:${this.addr}&referer=wisdom`);
        return;
      }
    },
    handleSubmit() {
      const toast = toastLoading();
      const data = {
        customer: this.customer,
        addr: this.addr,
        coord: this.coord,
        agent: this.agent,
        phone: this.phone,
        volts: this.volts,
        period: this.period,
        freq: this.freq,
        detect: this.detect,
        items: this.items.map(item => ({...item, qty: _.toSafeInteger(item.qty)})),
      };
      axios.post('/api/maintain-orders', data)
        .then(response => {
          const { data: { orderId } } = response;
          this.$router.push(`/order/info?orderId=${orderId}&for=checkout`);
        })
        .catch(error => {
          toast.clear();
          handleAPIError(error);
        });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    addItem() {
      this.items.push({ id: _.uniqueId('item_'), text: '', value: '', unit: '', qty: '', pickerVisible: false });
    },
    handleCascaderFinish(event, item) {
      const { selectedOptions } = event;
      item.text = selectedOptions.map(opt => opt.text).join('/');
      item.unit = _.last(selectedOptions).unit;
      item.pickerVisible = false;
    },
    handleLocPickerMessage(event) {
      const { data: { module, poiname, latlng } } = event;
      if (module != 'locationPicker') {
        return;
      }
      this.addr = poiname;
      this.coord = `${latlng.lat},${latlng.lng}`;
      this.locPickerVisible = false;
    },
    fetchOptions() {
      const toast = toastLoading();
      axios.get('/api/maintain-options')
        .then((response) => {
          const { data: { maintainOptions } } = response;
          this.options = maintainOptions;
        })
        .catch((error) => {
          handleAPIError(error);
        })
        .then(() => {
          toast.clear();
        });
    },
    getLocation() {
      const self = this;
      wx.getLocation({
        type: 'gcj02',
        success: function (res) {
          if (self.coord === '') {
            self.coord = `${res.latitude},${res.longitude}`;
          }
        }
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : wx.closeWindow();
    },
  },
};
</script>

<style>
.maintain-form.has-navbar {
  padding-top: 46px;
}
.maintain-form .van-field__error-message {
  color: #969799 !important;
}
</style>
