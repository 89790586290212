<template>
  <div class="staff-user-info" v-if="!!user">
    <van-nav-bar
      title="客户详情"
      left-text="返回"
      left-arrow
      @click-left="goBack"
      fixed
    />
    <van-form>
      <van-field
        label="单位"
        :value="user.org ? user.org.name : '未指定'"
        is-link
        readonly
        @click="openOrgSelector"
      />
      <van-field
        label="姓名"
        :value="user.name"
        readonly
      />
      <van-field
        label="手机号"
        :value="user.mobile"
        readonly
        clickable
        right-icon="phone-o"
        @click="dial"
      />
    </van-form>
    <staff-org-selector v-model="isOrgSelectorVisible" @change="handleOrgChange" @close="closeOrgSelector" />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import queryString from 'query-string';
import { Dialog, Toast } from 'vant';
import { handleAPIError, toastLoading, wxworkConfig } from './utils';
import OrgSelector from './OrgSelector.vue';

export default {
  data() {
    return {
      user: null,
      isOrgSelectorVisible: false,
    };
  },
  created() {
    wxworkConfig();
    this.fetchUserInfo();
  },
  methods: {
    handleOrgChange(org) {
      const toast = toastLoading();
      axios.put(`/api/staff/users/${this.user.id}`, { orgId: org.id })
        .then(response => {
          const { data: { message } } = response;
          const toast2 = Toast.success({
            duration: 0,
            forbidClick: true,
            message: message,
          });
          setTimeout(() => {
            toast2.clear();
            this.closeOrgSelector();
            this.fetchUserInfo();
          }, 1500);
        })
        .catch(error => {
          toast.clear();
          handleAPIError(error);
        });
    },
    closeOrgSelector() {
      this.isOrgSelectorVisible = false;
    },
    openOrgSelector() {
      this.isOrgSelectorVisible = true;
    },
    dial() {
      if (!this.$route.path.startsWith('/staff/')) {
        return;
      }
      if (navigator.userAgent.toLowerCase().includes('mobile')) {
        window.open(`tel:${this.user.phone}`);
        return;
      }
      Dialog.alert({
        message: '仅手机版企业微信支持拨打电话',
        confirmButtonColor: '#1989fa',
      });
    },
    fetchUserInfo() {
      const toast = toastLoading();
      const query = queryString.parse(window.location.search);
      const userId = _.get(query, 'userId');
      axios.get(`/api/staff/users/${userId}`)
        .then(response => {
          const { data: { user } } = response;
          this.user = user;
        })
        .catch(error => {
          handleAPIError(error);
        })
        .then(() => {
          toast.clear();
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : wx.closeWindow();
    },
  },
  components: {
    [OrgSelector.name]: OrgSelector,
  },
};
</script>

<style>
.staff-user-info {
  padding: 46px 0 0;
}
</style>
